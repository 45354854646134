import { TurnOrder, INVALID_MOVE } from 'boardgame.io/core';

const SPY = 0, GUARD = 1, PRIEST = 2, BARON = 3, HANDMAID = 4, PRINCE = 5, CHANCELLOR = 6, KING = 7, COUNTESS = 8, PRINCESS = 9;

const CARDS = {
	[SPY]: {
        name: 'Spy',
		text: 'If you are the only remaining player in the round who played a Spy, you get a point',
		guessable: true,
		onPlayed: ({G, events}) => end(G, events),
    },
	[GUARD]: {
        name: 'Guard',
		text: "Guess another player's card; if correct, they are out",
		guessable: false,
        onPlayed: ({G, events}) => targets(G) ? events.setStage('guess') : end(G, events),
        onGuess: ({G, events}, target, guess) => {
            const theirs = G.players[target].cards;
            if (theirs[0] == guess) {
                G.active = G.active.filter(id => id != target);
                G.played[target].push(theirs[0]);
                theirs.shift();
            }

            end(G, events);
        },
    },
	[PRIEST]: {
        name: 'Priest',
		text: "View another player's card",
		guessable: true,
        onPlayed: ({G, events}) => targets(G) ? events.setStage('target') : end(G, events),
        onTarget: ({G, playerID, events}, target) => {
            G.players[playerID].look = G.players[target].cards[0];
            events.setStage('look');
        },
		onLooked: ({G, playerID, events, log}) => {
			G.players[playerID].look = null;
			end(G, events);
		}
    },
	[BARON]: {
        name: 'Baron',
		text: "Compare cards with another player; lowest card is out",
		guessable: true,
        onPlayed: ({G, events}) => targets(G) ? events.setStage('target') : end(G, events),
        onTarget: ({G, playerID, events, log}, target) => {
            const mine = G.players[playerID].cards,
                theirs = G.players[target].cards;

            if (mine[0] > theirs[0]) {
                G.played[target].push(theirs.shift());
                G.active = G.active.filter(id => id != target);
            } else if (theirs[0] > mine[0]) {
                G.played[playerID].push(mine.shift());
                G.active = G.active.filter(id => id != playerID);
            }
            end(G, events);
        },
    },
	[HANDMAID]: {
        name: 'Handmaid',
		text: "Cannot be targeted until your next turn",
		guessable: true,
        onPlayed: ({G, playerID, events}) => {
            G.protected.push(playerID);
            end(G, events);
        },
    },
	[PRINCE]: {
        name: 'Prince',
		text: "Choose a player to discard and draw a new card",
		guessable: true,
		// cannot be played if the player is holding countess (countess must be played)
		canPlay: (hand) => hand.indexOf(COUNTESS) === -1,
        onPlayed: ({events}) => events.setStage('targetAny'),
        onTarget: ({G, events}, target) => {
            const hand = G.players[target].cards;

            const discarding = hand.shift();
            G.played[target].push(discarding);

            if (discarding == PRINCESS) {
                // if player is forced to discard the princess,
                // they're out and do not draw a new card
                G.active = G.active.filter(id => id != target);
            } else if (G.secret.deck.length) {
                hand.push(G.secret.deck.shift());
            } else if (G.secret.hidden !== null) {
                // if there are no cards left in the deck, the player takes
                // the reserved card -- we'll always have a reserved card here
                // because, if this happens, the game is over after this turn
                hand.push(G.secret.hidden);
                G.secret.hidden = null;
            }
            end(G, events);
        },
    },
	[CHANCELLOR]: {
        name: 'Chancellor',
		text: "Draw 2 cards, keep 1 and return 2 to the bottom of the deck",
		guessable: true,
        onPlayed: ({G, events, playerID}) => {
            const hand = G.players[playerID].cards,
                deck = G.secret.deck;

            if (deck.length) hand.push(deck.shift());
            if (deck.length) hand.push(deck.shift());

            if (hand.length > 1) {
                events.setStage('return');
            } else {
                end(G, events);
            }
        },
    },
	[KING]: {
        name: 'King',
		text: "Swap hands with another player",
		guessable: true,
		// cannot be played if the player is holding countess (countess must be played)
		canPlay: (hand) => hand.indexOf(COUNTESS) === -1,
        onPlayed: ({G, events}) => targets(G) ? events.setStage('target') : end(G, events),
        onTarget: ({G, playerID, events}, target) => {
            const mine = G.players[playerID].cards.shift(),
                theirs = G.players[target].cards.shift();

            G.players[playerID].cards.push(theirs);
            G.players[target].cards.push(mine);

            end(G, events);
        },
    },
	[COUNTESS]: {
        name: 'Countess',
		text: "Must play if holding Prince or King",
		guessable: true,
        onPlayed: ({G, events}) => end(G, events),
    },
	[PRINCESS]: {
        name: 'Princess',
		text: "If discarded at any time, player is out",
		guessable: true,
        onPlayed: ({G, playerID, events}) => {
			// need to discard the other card in our hand
			const {cards} = G.players[playerID];
			G.played[playerID].push(cards.shift());

            G.active = G.active.filter(id => id != playerID);
            end(G, events);
        },
    },
};

// starting distributions
const DECK = [SPY, SPY, GUARD, GUARD, GUARD, GUARD, GUARD, GUARD, PRIEST, PRIEST, BARON, BARON,
	HANDMAID, HANDMAID, PRINCE, PRINCE, CHANCELLOR, CHANCELLOR, KING, COUNTESS, PRINCESS];

function validPlayer(G, playerID) {
	// valid target if they're still playing and not protected
	return (G.active.indexOf(playerID) !== -1
		&& G.protected.indexOf(playerID) === -1);
}

function end(G, events) {
	if (G.secret.deck.length == 0 || G.active.length == 1) {
		// score the round
		const {scored, points} = scoreGame(G);
		G.score = points.reduce((s, [id, score]) => {
			s[id] += score;
			return s;
		}, G.score);

		// empty remaining hands
		G.active.forEach(id => {
			G.played[id].push(G.players[id].cards.shift());
		});
		G.winners = scored;

		// see if anyone has won the game
		const won = winners(G);
		if (won.length) {
			G.gameover = won;
			events.setPhase('winner');
		} else {
			events.setPhase('wait');
		}
	} else {
		events.endTurn();
	}
}

function validCard(card) {
	return card >= SPY && card <= PRINCESS;
}

function targets(G) {
	return G.protected.length < (G.active.length - 1);
}

function take(hand, card) {
	const i = hand.indexOf(card);
	if (i === -1)
		return false;
	hand.splice(i, 1);
	return true;
}

function scoreGame(G) {
	// game not over?
	if (G.active.length > 1 && G.secret.deck.length > 0)
		return;

	// find remaining active players and their current cards
	const active = G.active.map(id => [id, G.players[id].cards[0]]);

	// find active players who played a spy
	const playedSpy = G.active.filter(id => G.played[id].indexOf(SPY) !== -1);

	// find highest card (multiple players may have the same card)
	const highest = active.reduce((h, [,c]) => Math.max(h, c), 0);

	// multiple people can score if there's a tie
	const scored = active.filter(([, card]) => card == highest).map(([id]) => id);

	// one point for winning, one point if only active player who played a spy
	const points = scored.map(id => [id, 1]);
	if (playedSpy.length == 1) {
		points.push([playedSpy[0], 1]);
	}

	return {scored, points};
}

function winners(G) {
	const need = [6, 5, 4, 3, 3][Object.keys(G.players).length - 2];
	return Object.entries(G.score).filter(([, score]) => score >= need);
}

const LoveLetter = {
	name: 'loveletter',
	minPlayers: 2,
	maxPlayers: 6,
	disableUndo: true,

	events: {
		endGame: false,
		endTurn: false,
		endPhase: false,
		endStage: false,
	},

	setup: ({ctx}) => {
		// iniitialize player data
		const players = {};
		const played = {};
		const score = {};
		const active = [];

		for (let i = 0; i < ctx.numPlayers; i++) {
			const playerID = ""+i;
			players[playerID] = {
				// cards in hand (will only be unless it's our turn, or we're out)
				cards: [],
				// used for priest action
				look: null,
			};
			played[playerID] = [];
			score[playerID] = 0;
			active.push(playerID);
		}

		return {
            // turn info; card being played,
            // target (if applicable),
            // and guess (if guard)
            playing: null,
            target: null,
            guess: null,

			// winners of last round
			winners: null,
			// game over info (not using ctx.gameover)
			gameover: null,
			// current scores
			score,

			// players still in the round
			active: [],
			// players who played a handmaid
			protected: [],
			// cards played by each player
			played,
			// in two player game, three cards are revealed
			revealed: [],
			secret: {
				// cards remaining in draw pile
				deck: [],
				// "burnt" card
				hidden: null,
			},
			// each player's hand
			players,
		};
	},

	// endIf: ({G, ctx}) => {
	// 	const need = [6, 5, 4, 3, 3][ctx.numPlayers - 2];
	// 	const won = Object.entries(G.score).filter(([, score]) => score >= need);
	// 	if (won.length > 0) {
	// 		return {winners: won};
	// 	}
	// },

	phases: {
		wait: {
			onBegin: () => {
				console.log("wait begin");
			},
			moves: {
				ready: ({events}) => {
					events.setPhase('round');
				},
			},
			turn: {
				order: TurnOrder.CUSTOM([0]),
			},
			start: true,
			next: 'round',
		},

		round: {
			onBegin: ({G, random}) => {
				const players = Object.keys(G.players);
				const deck = random.Shuffle(DECK);
				const hidden = deck.shift();

				// in a two player game, three cards are revealed
				if (players.length == 2) {
					G.revealed = [deck.shift(), deck.shift(), deck.shift()];
				}

				players.forEach(id => {
					G.played[id] = [];
					G.players[id].cards = [deck.shift()];
					G.active.push(id);
				});
				G.protected = [];
				G.secret.deck = deck;
				G.secret.hidden = hidden;
				G.active = players;
			},

			turn: {
                // start in the discard stage
                activePlayers: {currentPlayer: 'discard'},

				onBegin: ({G, ctx}) => {
					const playerID = ctx.currentPlayer;

					console.log("onBegin for " + playerID);

					// draw a second card
					const drawn = G.secret.deck.shift();
					G.players[playerID].cards.push(drawn);

					// no longer protected
					G.protected = G.protected.filter(i => i != playerID);

                    // clear playing card and target
                    G.playing = null;
                    G.target = null;
                    G.guess = null;
				},

				onEnd: ({G, ctx, events}) => {
					console.log("onEnd for " + ctx.currentPlayer);

					G.playing = null;
					G.target = null;
					G.guess = null;

					// actual end of turn logic is in end();
					// can't trigger setPhase from onEnd?
				},

				order: {
					first: ({G, random}) => {
						if (G.winners) {
							const id = random.Shuffle(G.winners).shift();
							return Object.keys(G.players).indexOf(id);
						}
						return 0;
					},
					next: ({G, ctx}) => {
						if (G.active.length == 0)
							return 0;
						// find next active player
						let next = ctx.playOrderPos;
						do {
							next = (next + 1) % ctx.numPlayers;
						} while (G.active.indexOf(""+next) === -1);
						return next;
					},
				},

				stages: {
                    // start in discard stage, where we choose a card
                    discard: {
                        moves: {
                            discard: {
                                move:({G, playerID, events, log}, card) => {
                                    const hand = G.players[playerID].cards,
										C = CARDS[card];

									if (C.canPlay && !C.canPlay(hand)) {
										return INVALID_MOVE;
									}

                                    if (!take(hand, card)) {
                                        return INVALID_MOVE;
                                    }
                                    G.played[playerID].push(card);
									G.playing = card;

									log.setMetadata({played: G.playing, target: G.target, guess: G.guess});
                                    C.onPlayed({G, playerID, events});
                                },
                                client: false,
                            },
                        },
                    },

                    // target another player (not ourself)
                    target: {
                        moves: {
                            choose: {
                                move: ({G, playerID}, target) => {
                                    if (!validPlayer(G, target) || target === playerID) {
                                        return INVALID_MOVE;
                                    }
                                    G.target = target;
                                },
								client: false,
							},
							done: {
								move: ({G, playerID, events, log}) => {
									const card = G.playing,
										target = G.target;
									if (!target) {
										return INVALID_MOVE;
									}
									log.setMetadata({played: G.playing, target: G.target, guess: G.guess});
									CARDS[card].onTarget({G, playerID, events}, target);
								},
								client: false,
							},
                        },
                    },

                    // target any player (including ourself)
                    targetAny: {
                        moves: {
                            choose: {
                                move: ({G}, target) => {
                                    if (!validPlayer(G, target)) {
                                        return INVALID_MOVE;
                                    }
                                    G.target = target;
                                },
                                client: false,
                            },
                            done: {
                                move: ({G, playerID, events, log}) => {
                                    const card = G.playing,
                                        target = G.target;
									if (!target) {
										return INVALID_MOVE;
									}
									log.setMetadata({played: G.playing, target: G.target, guess: G.guess});
                                    CARDS[card].onTarget({G, playerID, events}, target);
                                },
                                client: false,
                            },
                        },
                    },

                    // guess a card when playing guard
                    guess: {
                        moves: {
							choose: {
								move: ({G, playerID}, target) => {
									if (!validPlayer(G, target) || target == playerID) {
										return INVALID_MOVE;
									}
									G.target = target;
								},
								client: false,
							},
                            guess: {
                                move: ({G}, guess) => {
                                    if (!validCard(guess) || !CARDS[guess].guessable) {
                                        return INVALID_MOVE;
                                    }
                                    G.guess = guess;
                                },
                                client: false,
                            },
                            done: {
                                move: ({G, events, playerID, log}) => {
									const {playing, target, guess} = G;
									if (!target || !guess) {
										return INVALID_MOVE;
									}
									log.setMetadata({played: playing, target: target, guess: guess});
                                    CARDS[playing].onGuess({G, events, playerID}, target, guess);
                                },
                                client: false,
                            },
                        },
                    },

                    // look at the outcome of a priest
                    look: {
                        moves: {
                            done: {
                                move: ({G, playerID, events, log}) => {
									const card = G.playing;
									log.setMetadata({played: G.playing, target: G.target, guess: G.guess});
									CARDS[card].onLooked({G, events, playerID});
                                },
                                client: false,
                            },
                        },
                    },

                    // returning cards when playing chancellor
                    return: {
                        moves: {
                            return: {
                                move: ({G, playerID, events, log}, card) => {
                                    const hand = G.players[playerID].cards;

                                    if (!take(hand, card)) {
                                        return INVALID_MOVE;
                                    }
                                    G.secret.deck.push(card);

                                    if (hand.length == 1) {
										log.setMetadata({played: G.playing, target: G.target, guess: G.guess});
                                        end(G, events);
                                    }
                                },
                                client: false,
                            },
                        },
                    },
				},
			},
        },

		winner: {
			onBegin: () => {
				console.log('winner begin');
			},
			moves: {
				reset: ({ctx, events}) => {
					events.setPhase('round');
					// set up a whole new game
					return LoveLetter.setup({ctx});
				},
			},
			turn: {
				order: TurnOrder.CUSTOM([0]),
			},
		},
	},

	turn: {
	},

	// removes 'secret' and filters 'players'
	playerView: (context) => {
		const G = {...context.G}, {playerID} = context;
		G.remaining = G.secret.deck.length;
		G.players = playerID ? {[playerID]: G.players[playerID]} : {};
		G.secret = {};
		return G;
	}
};


export { LoveLetter, CARDS, SPY, GUARD, PRIEST, BARON, HANDMAID, PRINCE, CHANCELLOR, KING, COUNTESS, PRINCESS };